/*
 * @Desc: 
 * @Author: BergTang
 * @Email: bergtang@qq.com
 * @Date: 2020-11-06 11:53:27
 * @LastEditors: BergTang
 * @LastEditTime: 2020-11-08 10:23:35
 */
export default {
    // API请求域名
    api_base_url : 'https://infashions.shop',
    // cookie有效根域名
    cookies_domain:'rosa9981.com',
    // 可访问域名
    open_domain:'rosa9981.com',
    pay_domain:'rosa9981.com',
    web_site_name:'山水芙蓉',
    line:'@602kfgqa',
    shop_service:'line://ti/p/@602kfgqa',
    service_hotline:'0968699981',
    shop_service_email:'lucyliao47@gmail.com',
    facebook_home:'https://www.facebook.com/山水芙蓉-104705038525562',
    messenger:'https://m.me/104705038525562',
    bank_name:'中國信託 代號822',
    bank_no:'078540225500',
    bank_user:'尼波'
}