import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '../utils/config.js'
//解决同页面跳转的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        meta: {
            title: '山水芙蓉',
            index: 1
        },
        component: () =>
            import ('../views/index.vue')
    },
    {
        path: '/list',
        name: 'list',
        meta: {
            title: '山水芙蓉',
            index: 1
        },
        component: () =>
            import ('../views/list.vue')
    },
    {
        path: '/category',
        name: 'category',
        meta: {
            title: '分類 - 山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/category.vue')
    },
    {
        path: '/cart',
        name: 'cart',
        meta: {
            title: '購物車 - 山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/cart.vue')
    },
    {
        path: '/query',
        name: 'query',
        meta: {
            title: '查詢 - 山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/query.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        meta: {
            title: '山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/detail.vue')
    },
    {
        path: '/AboutUs',
        name: 'AboutUs',
        meta: {
            title: '關於我們',
            index: 3
        },
        component: () =>
            import ('../views/AboutUs.vue')
    },
    {
        path: '/ReturnPolicy',
        name: 'ReturnPolicy',
        meta: {
            title: '退換貨政策',
            index: 3
        },
        component: () =>
            import ('../views/ReturnPolicy.vue')
    },
    {
        path: '/ServiceTerms',
        name: 'ServiceTerms',
        meta: {
            title: '服務政策',
            index: 3
        },
        component: () =>
            import ('../views/ServiceTerms.vue')
    },
    {
        path: '/PrivacyPolicy',
        name: 'PrivacyPolicy',
        meta: {
            title: '隱私條款',
            index: 3
        },
        component: () =>
            import ('../views/PrivacyPolicy.vue')
    },
    {
        path: '/Questions',
        name: 'Questions',
        meta: {
            title: '常見問題',
            index: 3
        },
        component: () =>
            import ('../views/Questions.vue')
    },
    {
        path: '/footprint',
        name: 'footprint',
        meta: {
            title: '瀏覽記錄',
            index: 3
        },
        component: () =>
            import ('../views/common/footprint.vue')
    },
    {
        path: '/confirm',
        name: 'confirm',
        meta: {
            title: '確認訂單 - 山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/confirm.vue')
    },
    {
        path: '/tags',
        name: 'tags',
        meta: {
            title: '商品類型 - 山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/tags.vue')
    },
    {
        path: '/success',
        name: 'success',
        meta: {
            title: '下單完成 - 山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/success.vue')
    },
    {
        path: '/orderinfo',
        name: 'orderinfo',
        meta: {
            title: '訂單詳情 - 山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/orderinfo.vue')
    },
    {
        path: '/search',
        name: 'search',
        meta: {
            title: '商品搜尋 - 山水芙蓉',
            index: 3
        },
        component: () =>
            import ('../views/search.vue')
    },
    {
        path: '/lvpay',
        name: 'lvpay',
        meta: {
            title: '信用卡付款',
            index: 3
        },
        component: () =>
            import ('../views/lvpay.vue')
    },


]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {

    // document.body.scrollTop = 0 //返回顶部
    // document.documentElement.scrollTop = 0
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        //document.title = to.meta.title + ' - ' + config.title
        document.title = to.meta.title
    }
    next()
})

export default router